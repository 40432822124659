
let searchParams = {};
let panoramaImages = [];
window.onload = function () {
    searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("imagesrc")) {
        panoramaImages = searchParams.get("imagesrc").split(',');
    }
    setTimeout(() => {
        const sceneComp =
            `<a-scene vr-mode-ui="enabled: false" cursor="rayOrigin: mouse; fuse: false" raycaster="objects: .switchentity">
            <a-assets>
                ${init_a_assets()}
                ${init_switchTipsAssets()}
            </a-assets>

            <a-camera id='cam'></a-camera>

            ${init_a_sky()}

            <a-entity id="links" layout="type: line; margin: 1.1" position="-0.5 1.7 -5">
                ${init_switch_a_entity()}
            </a-entity>

            ${initSwitchtipsImage()}
            
        </a-scene>`
        document.getElementById('mainFrame').innerHTML = sceneComp;
        const title = searchParams.get("title");
        document.title = title == null ? 'VR看鱼塘' : title
        setEventListener();
    }, 100);
}

function setEventListener() {
    setTimeout(() => {
        window.addEventListener("wheel", (event) => {
            const delta = event.wheelDelta / 120 / 10;
            var mycam = document.getElementById("cam").getAttribute("camera");
            var finalZoom = document.getElementById("cam").getAttribute("camera").zoom + delta;
            if (finalZoom < 0.5) finalZoom = 0.5;
            if (finalZoom > 3) finalZoom = 3;
            mycam.zoom = finalZoom;
            document.getElementById("cam").setAttribute("camera", mycam);
        });
    }, 500);
}

function init_a_sky() {
    return `<a-sky id='panor-sky' src='#p0_panorama'></a-sky>`
}

function init_a_cursor() {
    if (panoramaImages.length <= 1) {
        return '';
    }
    return `<a-cursor
    id="cursor"
    animation__click="property: scale; from: 0.1 0.1 0.1; to: 1 1 1; easing: easeInCubic; dur: 150; startEvents: click"
    animation__clickreset="property: scale; to: 1 1 1; dur: 1; startEvents: animationcomplete__click"
    animation__fusing="property: scale; from: 1 1 1; to: 0.1 0.1 0.1; easing: easeInCubic; dur: 150; startEvents: fusing"></a-cursor>`
}

function init_a_assets() {
    let assets = '';
    panoramaImages.map((value, i) => {
        assets += `<img id="p${i}_panorama" crossorigin="anonymous" src="${value}">`
    })
    return assets;
}

function init_switchTipsAssets() {
    if (panoramaImages.length <= 1) {
        return '';
    }
    return `<img id="switchtips" crossorigin="anonymous" src="https://luujee.oss-cn-shanghai.aliyuncs.com/uiImages/VRSwitchTips.png">`;
}

function init_switch_a_entity() {
    if (panoramaImages.length <= 1) {
        return '';
    }
    let entities = '';
    panoramaImages.map((value, i) => {
        entities += ` <a-entity id="p${i}_switchEntity" class="switchentity" geometry="primitive: sphere; radius: 0.38;" 
                        material="shader: flat; src: ${value}" tag="${value}"
                        event-set__mouseenter="scale: 1.2 1.2 1"
                        event-set__mouseleave="scale: 1 1 1"
                        event-set__click="_target: #panor-sky; _delay: 300; material.src: ${value}"></a-entity>`
    });
    return entities;
}

function initSwitchtipsImage() {
    if (panoramaImages.length <= 1) {
        return '';
    }
    let x = ((0.38 * 2 * panoramaImages.length) + 2.2 - 2) / 2 - 0.5;
    return `<a-image src="#switchtips" crossorigin="anonymous" width="2" height="0.6" position="${x} 0.9 -5"></a-image>`;
}

